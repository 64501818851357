import { Check } from 'react-feather';
import ToastWrapper from './ToastWrapper';

export const successToast = (title: string) => ToastWrapper(
  'Changes are saved!',
  title,
  <Check size={12} />,
  'success',
);

export default successToast;
