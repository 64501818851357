import { Trash } from 'react-feather';
import ToastWrapper from './ToastWrapper';

export const deleteToast = (title: string) => ToastWrapper(
  'Changes are saved!',
  title,
  <Trash size={12} />,
  'warning',
);

export default deleteToast;
