// ** Reducers Imports
import layout from './layout';
import auth from './authentication';
import navbar from './navbar';
import members from '../views/apps/employees/store';
import reports from '../views/apps/reports/store';

const rootReducer = {
  auth,
  layout,
  navbar,
  members,
  reports,
};

export default rootReducer;
