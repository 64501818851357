import { X } from 'react-feather';
import ToastWrapper from './ToastWrapper';

export const errorToast = () => ToastWrapper(
  'Oops!',
  <>
    <b>Something Went Wrong!
      <span role="img" aria-label="emoji">😖</span>
    </b>
    <br />
    <hr />
    There seems to be an error, please try again or check back later!
  </>,
  <X size={12} />,
  'danger',
);

export default errorToast;
