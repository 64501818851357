// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports
import axios from 'axios';

import Report from '../types/Report';

const initReports: Report[] = [];

interface MontlyReportRequest {
  month: number | string;
  year: number | string;
}

interface NewVisit {
  location_token:string;
  membership_code: string;
}

export const getAllReports = createAsyncThunk('members/getAllReports',
  async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_URL_ENV}/admin/dash/visits`,
    );
    return {
      data: response.data,
    };
  });

export const addNewVisit = async (req: NewVisit) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_URL_ENV}/admin/dash/new-visit`, req,
    );
    return response.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const filterReports = createAsyncThunk('members/getAllReports',
  async (req?:MontlyReportRequest) => {
    const response = await axios.get(
      `${process.env.REACT_APP_URL_ENV}/admin/dash/visits?month=${req?.month}&year=${req?.year}`,
    );
    return {
      data: response.data,
    };
  });

export const filterMembers = createAsyncThunk('members/filterMembers', async (memberId?: number | string) => {
  const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/admin/dash/members/${memberId}/visits`);
  return {
    data: response.data,
  };
});

export const appUsersSlice = createSlice({
  name: 'reports',
  initialState: {
    data: initReports,
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllReports.fulfilled, (state, action) => {
        state.data = action.payload.data;
      });
    builder
      .addCase(filterMembers.fulfilled, (state, action) => {
        state.data = action.payload.data;
      });
  },
});

export default appUsersSlice.reducer;
