// ** Logo
import logo from '@src/assets/images/logo/BizfitDark.svg';

function SpinnerComponent() {
  return (
    <div className="fallback-spinner app-loader">
      <img className="fallback-logo" src={logo} alt="logo" width={50} height={50} />
      <div className="loading">
        <div className="effect-1 effects" />
        <div className="effect-2 effects" />
        <div className="effect-3 effects" />
      </div>
    </div>
  );
}

export default SpinnerComponent;
