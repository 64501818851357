// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports
import axios from 'axios';
import { errorToast } from '../../../../components/ErrorToast';
import { successToast } from '../../../../components/SuccessToast';
import { deleteToast } from '../../../../components/DeleteToast';
import Employee from '../types/Employee';
import BulkUpdateRequest from '../types/BulkUpdateRequest';

const initMembers: Employee[] = [];

export const getAllMembers = createAsyncThunk('members/getAllMembers', async () => {
  const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/admin/dash/members`);
  return {
    data: response.data,
  };
});

export const addNewMember = createAsyncThunk('members/addNewMember', async (values: Employee) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/admin/dash/members`, values);
    successToast('New employee successfully added!');
    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
});

export const updateMember = async (memberId: number, values: Employee) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/admin/dash/members/${memberId}`, values);
    successToast('Information about employee successfully updated!');
    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
};

export const deleteMember = async (memberId: number) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_URL_ENV}/admin/dash/members/${memberId}`);
    deleteToast('Employee successfully deleted!');
    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
};

export const resetPasword = async (newPassword: string) => {
  const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/admin/dash/password-reset`, { new_password: newPassword });
  return {
    data: response.data,
  };
};

export const updateSpendingLimit = async (updateRequest: BulkUpdateRequest) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/admin/dash/members/bulk-update`, updateRequest);
    successToast('Spending limit successfully updated!');
    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
};

export const appUsersSlice = createSlice({
  name: 'members',
  initialState: {
    data: initMembers,
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllMembers.fulfilled, (state, action) => {
        state.data = action.payload.data;
      });
  },
});

export default appUsersSlice.reducer;
